import './App.css';
import { useState, useEffect } from 'react';
import moment from "moment"
import 'moment/locale/es';

var encuentro="2023-06-24"
var bautizo="2023-05-01"
var evangelismo="2023-05-17"
var multiplicacion="2023-05-25"
var graduacion="2023-06-16"

moment.locale('es'); // aca ya esta en es
function App() {
  const [date, setDate] = useState(new Date());
  
  // function refreshClock() {
  //   setDate(new Date());
  // }

  // useEffect(() => {
  //   const timerId = setInterval(refreshClock, 1000);
  //   return function cleanup() {
  //     clearInterval(timerId);
  //   };
  // }, []);

  return (
    <div className="App">
      <header className="App-header">
      <p>Nuestro Próximo <b>Evangelismo </b><br/>es <span>{moment(evangelismo, "YYYYMMDD").fromNow()}</span></p>
      <p>Nuestros Próximos <b>Bautizos</b><br/> son <span>{moment(bautizo, "YYYYMMDD").fromNow()}</span></p>
      <p>Nuestro Próximo <b>Encuentro</b><br/> es <span>{moment(encuentro, "YYYYMMDD").fromNow()}</span></p>
      <p>Nuestra Próxima <br/><b>Multiplicación de Células </b><br/> es <span>{moment(multiplicacion, "YYYYMMDD").fromNow()}</span></p>
      <p>Nuestra Próxima <br/><b>Ceremonia de Graduacion de Discipulado </b><br/> es <span>{moment(graduacion, "YYYYMMDD").fromNow()}</span></p>
      </header>
    </div>
  );
}

export default App;
